import "./App.css";
import { ThemeProvider } from "@emotion/react";
import { Route, Routes } from "react-router-dom";

import { FstStTheme } from "./assets/fstst-theme";
import { DataPrivacy } from "./components/DataPrivacy";
import { FstStBar } from "./components/FstStBar";
import { Imprint } from "./components/Imprint";
import { Layout } from "./components/Layout";
import { NotFound } from "./components/NotFound";
import { IpOverview } from "./views/Dashboard";

export function App() {
  return (
    <ThemeProvider theme={FstStTheme}>
      <FstStBar>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<IpOverview />} />
            <Route path="imprint" element={<Imprint />} />
            <Route path="data-privacy" element={<DataPrivacy />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </FstStBar>
    </ThemeProvider>
  );
}
