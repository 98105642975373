import { Grid } from "@mui/material";

import { BrowserInfo } from "../components/BrowserInfo";
import { HostInfo } from "../components/IpifyInfo";
import { OsmMap } from "../components/OsmMap";
import { ShowGeoData } from "../components/ShowGeoData";
import { GeoDataProvider } from "../contexts/GeoDataContext";

export function IpOverview() {
  return (
    <GeoDataProvider>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <HostInfo />
        </Grid>

        <Grid item md={6} xs={12}>
          <BrowserInfo />
        </Grid>

        <Grid item md={6} xs={12}>
          <ShowGeoData />
        </Grid>

        <Grid item md={6} xs={12}>
          <OsmMap />
        </Grid>
      </Grid>
    </GeoDataProvider>
  );
}
