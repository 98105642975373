import {
  Box,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import { useGeoDataContext } from "../contexts/GeoDataContext";

import type { LatLngExpression } from "leaflet";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

export function OsmMap() {
  const [consented, setConsented] = useState(false);

  const geoDataContext = useGeoDataContext();

  if (!geoDataContext) {
    return <></>;
  }
  const { geoData } = geoDataContext;

  function ConsentToShowMap() {
    return (
      <Paper elevation={3}>
        <Box sx={{ p: 3 }}>
          <Typography>
            Internet-Zugangspunkt auf OpenStreetMaps anzeigen
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={<Switch onChange={() => setConsented(!consented)} />}
              label=""
            />
          </FormGroup>
        </Box>
      </Paper>
    );
  }

  function PaperedMap({ geoPosition }: { geoPosition: LatLngExpression }) {
    return (
      <Paper elevation={3}>
        <MapContainer
          center={geoPosition}
          zoom={10}
          style={{ height: "100vh", width: "100wh" }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={geoPosition}>
            <Popup>you are entering the internet here!</Popup>
          </Marker>
        </MapContainer>
      </Paper>
    );
  }

  if (!consented) {
    return <ConsentToShowMap />;
  }

  if (
    !geoData ||
    typeof geoData !== "object" ||
    !geoData.longitude ||
    !geoData.latitude
  ) {
    return (
      <Paper elevation={3}>
        <Box sx={{ p: 3 }}>
          <Typography>Warte auf GPS Koordinaten...</Typography>
        </Box>
      </Paper>
    );
  }

  return <PaperedMap geoPosition={[geoData.latitude, geoData.longitude]} />;
}
