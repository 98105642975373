import { createTheme } from "@mui/material";

export const FstStTheme = createTheme({
  palette: {
    primary: {
      main: "#ffa000",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});
