import { createContext, useContext, useEffect, useState } from "react";

import { useIpify } from "../hooks/useIpify";

import type { GeoData } from "../types/GeoData";
import type { ReactElement } from "react";

export enum IPVersion {
  IP4 = "loading",
  IP6 = "success",
}

export const IP4 = "ipv4";
export const IP6 = "ipv6";

export type GeoDataContextType = {
  ip4?: string;
  ip6?: string;
  geoData?: GeoData;
  isLoadingGeoData?: boolean;
  errorLoadingGeoData?: string;
  ipV: IPVersion;
  setIpV?: React.Dispatch<React.SetStateAction<IPVersion>>;
};

const GeoDataContext = createContext<GeoDataContextType | undefined>(undefined);

function getHost(ip: string | undefined) {
  if (ip) {
    return `https://ipapi.co/${ip}/json/`;
  }
  return "https://ipapi.co/json/";
}

function fetchGeoData({
  ip,
  setGeoData,
  setLoading,
  setError,
}: {
  ip?: string;
  setGeoData?: React.Dispatch<React.SetStateAction<GeoData | undefined>>;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setError?: React.Dispatch<React.SetStateAction<string | undefined>>;
}): void {
  if (!ip || !setGeoData || !setError || !setLoading) {
    return;
  }
  setLoading(true);
  const host = getHost(ip);
  fetch(host).then((response) => {
    response
      .json()
      .then((res: GeoData) => {
        if (res) {
          setLoading(false);
          setGeoData(res);
          return;
        }
        setError(
          `could not fetch ip addr information from ${host}: error: could not parse json`
        );
        setLoading(false);
      })
      .catch((err) => {
        setError(
          `could not fetch ip addr information from ${host}: ${JSON.stringify(
            err
          )}`
        );
        setLoading(false);
      })
      .catch((err: Error) => {
        setError(
          `could not fetch ip addr information from ${host}: ${JSON.stringify(
            err
          )}`
        );
        setLoading(false);
      });
  });
}

export function GeoDataProvider({ children }: { children: ReactElement }) {
  const { ip4, ip6 } = useIpify();
  const [geoDataIp4, setGeoDataIp4] = useState<GeoData | undefined>(undefined);
  const [isInitialLoadingIp4, setIsInitialLoadingIp4] = useState(true);
  const [isLoadingIp4, setIsLoadingIp4] = useState(false);
  const [errorIp4, setErrorIp4] = useState<string | undefined>(undefined);

  const [geoDataIp6, setGeoDataIp6] = useState<GeoData | undefined>(undefined);
  const [isInitialLoadingIp6, setIsInitialLoadingIp6] = useState(true);
  const [isLoadingIp6, setIsLoadingIp6] = useState(false);
  const [errorIp6, setErrorIp6] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (
      !ip4 ||
      (!isInitialLoadingIp4 && (isLoadingIp4 || errorIp4 || geoDataIp4))
    ) {
      return;
    }
    setIsInitialLoadingIp4(false);
    setIsLoadingIp4(true);
    fetchGeoData({
      ip: ip4,
      setGeoData: setGeoDataIp4,
      setLoading: setIsLoadingIp4,
      setError: setErrorIp4,
    });
  }, [errorIp4, geoDataIp4, ip4, isLoadingIp4, isInitialLoadingIp4]);

  useEffect(() => {
    if (
      !ip6 ||
      (!isInitialLoadingIp6 && (isLoadingIp6 || errorIp6 || geoDataIp6))
    ) {
      return;
    }
    setIsInitialLoadingIp6(false);
    fetchGeoData({
      ip: ip6,
      setGeoData: setGeoDataIp6,
      setLoading: setIsLoadingIp6,
      setError: setErrorIp6,
    });
  }, [errorIp6, geoDataIp6, ip6, isLoadingIp6, isInitialLoadingIp6]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ipV, setIpV] = useState<IPVersion>(IPVersion.IP4);

  const geoData = ipV === IPVersion.IP4 ? geoDataIp4 : geoDataIp6;
  const isLoadingGeoData =
    ipV === IPVersion.IP4
      ? isLoadingIp4 || isInitialLoadingIp4
      : isLoadingIp6 || isInitialLoadingIp6;
  const errorLoadingGeoData = ipV === IPVersion.IP4 ? errorIp4 : errorIp6;

  return (
    <GeoDataContext.Provider
      value={{
        ip4,
        ip6,
        geoData,
        isLoadingGeoData,
        errorLoadingGeoData,
        ipV,
        setIpV,
      }}
    >
      {children}
    </GeoDataContext.Provider>
  );
}

export function useGeoDataContext() {
  return useContext(GeoDataContext);
}
