import {
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import { useIpify } from "../hooks/useIpify";

export function HostInfo() {
  const {
    ip4,
    isLoadingIp4,
    errorIp4,
    ip6,
    isLoadingIp6,
    errorIp6,
    ip64,
    isLoadingIp64,
    errorIp64,
  } = useIpify();

  return (
    <Paper elevation={3}>
      <Box sx={{ p: 2 }}>
        <Box sx={{ p: 1 }}>
          <Typography component={"h1"}>Data from api.ipify.org</Typography>
        </Box>
        <TableContainer>
          <Table sx={{ width: "80%" }} aria-label="Host Info">
            <TableBody>
              <TableRow
                key="ip4"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {"you connect to other hosts using ip (only ip4): "}
                </TableCell>
                <TableCell align="right">
                  {isLoadingIp4 ? <LinearProgress /> : errorIp4 || ip4}
                </TableCell>
              </TableRow>

              <TableRow
                key="ip6"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {"you connect to other hosts using ip (only ip6): "}
                </TableCell>
                <TableCell align="right">
                  {isLoadingIp6 ? <LinearProgress /> : errorIp6 || ip6}
                </TableCell>
              </TableRow>

              <TableRow
                key="ip64"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {"you connect to other hosts using ip (using dual stack): "}
                </TableCell>
                <TableCell align="right">
                  {isLoadingIp64 ? <LinearProgress /> : errorIp64 || ip64}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}
