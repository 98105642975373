import { Box, Paper, Typography } from "@mui/material";
import {
  browserName,
  deviceType,
  engineName,
  engineVersion,
  fullBrowserVersion,
  isMobile,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from "react-device-detect";

const infos = () => {
  const entries = [
    { label: "Device Type", value: deviceType },
    { label: "OS Name", value: osName },
    { label: "OS Version", value: osVersion },
    { label: "Browser Name", value: browserName },
    { label: "Browser Version", value: fullBrowserVersion },
    { label: "Engine Name", value: engineName },
    { label: "Engine Version", value: engineVersion },
    // { label: "", value:  },
    // { label: "", value:  },
  ];
  if (isMobile) {
    entries.push({ label: "mobile vendor", value: mobileVendor });
    entries.push({ label: "mobile model", value: mobileModel });
  }
  return entries;
};

export function BrowserInfo() {
  // const { userAgent } = window.navigator;

  return (
    <Paper elevation={3}>
      <Box sx={{ p: 1 }}>
        <Box sx={{ p: 2 }}>
          <Typography component={"h1"}>
            Information about detected Browser, Device, ...
          </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <>
            {infos().map((elem) => (
              <Typography key={elem.label}>
                <>
                  {elem.label}: {elem.value}
                </>
              </Typography>
            ))}
          </>
        </Box>
      </Box>
    </Paper>
  );
}
