import { useEffect, useState } from "react";

import { fetchWithTimeout } from "../lib/fetchWithTimeout";

function getIp({
  host,
  setIp,
  setLoading,
  setError,
}: {
  host: string;
  setIp?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setError?: React.Dispatch<React.SetStateAction<string | undefined>>;
}): void {
  if (!setIp || !setError || !setLoading) {
    return;
  }
  setLoading(true);
  const controller = new AbortController();

  fetchWithTimeout(host, { signal: controller.signal })
    .then((response) => {
      response
        .json()
        .then((res) => {
          if (!res) {
            setError(
              `could not determine ip addr from ${host}: error: could not parse json`
            );
            setLoading(false);
            return;
          }
          setIp(res.ip);
          setLoading(false);
        })
        .catch((err) => {
          setError(
            `could not determine ip4 addr from ${host}: ${JSON.stringify(err)}`
          );
          setLoading(false);
        });
    })
    .catch((err: Error) => {
      if (err.name === "AbortError") {
        setError(`request timed out.`);
      } else {
        setError(`could not determine ip addr from ${host}: ${err}`);
      }
      setLoading(false);
    });
}

export function useIpify() {
  const [ip4, setIp4] = useState<string | undefined>(undefined);
  const [isLoadingIp4, setIsLoadingIp4] = useState(false);
  const [errorIp4, setErrorIp4] = useState<string | undefined>(undefined);

  const [ip6, setIp6] = useState<string | undefined>(undefined);
  const [isLoadingIp6, setIsLoadingIp6] = useState(false);
  const [errorIp6, setErrorIp6] = useState<string | undefined>(undefined);

  const [ip64, setIp64] = useState<string | undefined>(undefined);
  const [isLoadingIp64, setIsLoadingIp64] = useState(false);
  const [errorIp64, setErrorIp64] = useState<string | undefined>(undefined);

  const host4 = "https://api.ipify.org?format=json";
  const host6 = "https://api6.ipify.org?format=json";
  const host64 = "https://api64.ipify.org?format=json";

  useEffect(() => {
    if (isLoadingIp4 || errorIp4 || ip4) {
      return;
    }
    getIp({
      host: host4,
      setIp: setIp4,
      setLoading: setIsLoadingIp4,
      setError: setErrorIp4,
    });
  }, [errorIp4, ip4, isLoadingIp4]);

  useEffect(() => {
    if (isLoadingIp6 || errorIp6 || ip6) {
      return;
    }
    getIp({
      host: host6,
      setIp: setIp6,
      setLoading: setIsLoadingIp6,
      setError: setErrorIp6,
    });
  }, [errorIp6, ip6, isLoadingIp6]);

  useEffect(() => {
    if (isLoadingIp64 || errorIp64 || ip64) {
      return;
    }
    getIp({
      host: host64,
      setIp: setIp64,
      setLoading: setIsLoadingIp64,
      setError: setErrorIp64,
    });
  }, [errorIp64, ip64, isLoadingIp64]);

  return {
    ip4,
    isLoadingIp4,
    errorIp4,
    ip6,
    isLoadingIp6,
    errorIp6,
    ip64,
    isLoadingIp64,
    errorIp64,
    isLoading: isLoadingIp4 || isLoadingIp6 || isLoadingIp64,
  };
}
