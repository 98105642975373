export const FETCH_TIMEOUT_IN_MS = 5000;

export function fetchWithTimeout(
  url: string,
  { signal, ...options }: { signal: AbortSignal }
) {
  const controller = new AbortController();
  const promise = fetch(url, { signal: controller.signal, ...options });
  if (signal) signal.addEventListener("abort", () => controller.abort());
  const timeout = setTimeout(() => controller.abort(), FETCH_TIMEOUT_IN_MS);
  return promise.finally(() => clearTimeout(timeout));
}
