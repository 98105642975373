import {
  Box,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import { IPVersion, useGeoDataContext } from "../contexts/GeoDataContext";

import type { GeoData } from "../types/GeoData";
import type { ReactElement } from "react";

/* 
ip	2001:470:7304:12:c156:1f0c:fb2a:aa68
network	2001:470:7300::/43
version	IPv6
city	Frankfurt am Main
region	Hesse
region_code	HE
country	DE
country_name	Germany
country_code	DE
country_code_iso3	DEU
country_capital	Berlin
country_tld	.de
continent_code	EU
in_eu	true
postal	60313
latitude	50.1188
longitude	8.6843
timezone	Europe/Berlin
utc_offset	+0100
country_calling_code	+49
currency	EUR
currency_name	Euro
languages	de
country_area	357021
country_population	82927922
asn	AS6939
org	HURRICANE
*/

interface DataRowProps {
  keyName: string;
  val?: string | ReactElement;
}

function DataRow(props: DataRowProps) {
  const { keyName, val } = props;
  return (
    <TableRow
      key={`${keyName}-${val}`}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {keyName}
      </TableCell>
      <TableCell align="left">{val || <Skeleton />}</TableCell>
    </TableRow>
  );
}

interface RowsProps {
  geoData?: GeoData;
}

function Rows(props: RowsProps) {
  const { geoData } = props;
  const keys = [
    "ip",
    "network",
    "asn",
    "org",
    "postal",
    "city",
    "region",
    "region_code",
    "timezone",
    "utc_offset",
    "country_name",
    "country_code",
    "continent_code",
  ] as (keyof GeoData)[];

  return (
    <>
      {keys.map((keyName) => (
        <DataRow
          key={keyName}
          keyName={keyName}
          val={geoData ? String(geoData[keyName]) : undefined}
        />
      ))}
    </>
  );
}

function embedChildren(children: ReactElement) {
  return <Paper elevation={3}>{children}</Paper>;
}

export function ShowGeoData() {
  const geoDataContext = useGeoDataContext();

  if (!geoDataContext) {
    return <Skeleton />;
  }
  const { geoData, errorLoadingGeoData, ipV, setIpV } = geoDataContext;

  function changeIpV() {
    if (!setIpV) {
      return;
    }
    if (ipV === IPVersion.IP4) {
      setIpV(IPVersion.IP6);
      return;
    }
    setIpV(IPVersion.IP4);
  }

  if (errorLoadingGeoData) {
    return embedChildren(<>{errorLoadingGeoData}</>);
  }

  return embedChildren(
    <Box sx={{ p: 2 }}>
      <Box sx={{ p: 1 }}>
        <Typography component={"h1"}>Data from ipapi.co</Typography>

        <Typography>
          IPv4
          <Switch onChange={changeIpV} />
          IPv6
        </Typography>
      </Box>
      <TableContainer>
        <Table sx={{ width: "80%" }} aria-label="IP Infos">
          <TableBody>
            <Rows geoData={geoData} />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
